// <template>
  <div ref="container">
    <b-card-title class="card-title mt-1 mx-2">
      <b-row class="row">
        <b-col cols="7">
          <button
            class="btn btn-sm button-primary mr-1"
            @click="onAdd"
            v-if="isAdminOrStaff"
          >
          <feather-icon
            size="15"
            icon="PlusSquareIcon"
            class="mr-50"
          />
          <span style="font-size: 15px">Agregar Localidad</span>
          </button>

          <button class="btn btn-sm btn-primary" @click="onXml">
            <feather-icon
              size="15"
              icon="DownloadIcon"
              class="mr-50"
            />
            <span style="font-size: 15px">Descargar XLS</span>
          </button>

          <router-link
            class="btn btn-sm button-tertiary text-white ml-1"
            to="/dashboard/locations/upload"
            title="Actualizar"
          >
            <feather-icon
              size="15"
              icon="UploadIcon"
              class="mr-50"
            />
            <span style="font-size: 15px">Subir XLS</span>
          </router-link>
        </b-col>
        <b-col cols="5">
          <filter-bar-component v-on:search="onSearch">
            <b-form-select
              size="sm"
              :options="provinces"
            ></b-form-select>
          </filter-bar-component>
        </b-col>
      </b-row>
    </b-card-title>
    <b-card-body class="card-body py-2 px-0">
      <full-table-component
        ref="fulltable"
        :perPage="100"
        :fields="fields"
        url="/locations"
      >
        <template slot="buttons-slot" slot-scope="props">
          <b-dropdown
            class="p-0"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                size="20"
                icon="ListIcon"
              />
            </template>
            <b-dropdown-item @click="onView(props.rowData)">
              <feather-icon
                size="20"
                icon="SearchIcon"
              />
              Ver detalles
            </b-dropdown-item>
            <b-dropdown-item @click="onEdit(props.rowData)">
              <feather-icon
                size="20"
                icon="Edit3Icon"
              />
              Editar
            </b-dropdown-item>
            <b-dropdown-item @click="onDelete(props.rowData)">
              <feather-icon
                size="20"
                icon="TrashIcon"
              />
              Eliminar
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </full-table-component>
    </b-card-body>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCardTitle,
  BRow,
  BCol,
  BFormSelect,
  BCardBody,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";

import FilterBarComponent from "@/layouts/components/FilterBarComponent";
import FullTableComponent from "@/layouts/components/FullTableComponent";

import LocationService from "@/services/LocationService";
import AlertService from "@/services/AlertService";
import UtilService from "@/services/UtilService";
import UserService from "@/services/UserService";

import LocationEdit from "./LocationEdit";
import LocationView from "./LocationView";
import moment from "moment";

export default {
  name: "LocationAll",
  components: {
    BCardTitle,
    BRow,
    BCol,
    BFormSelect,
    BCardBody,
    BDropdown,
    BDropdownItem,
    FilterBarComponent,
    FullTableComponent
  },
  data() {
    return {
      provinces: [],
      fields: [
        {
          name: "id",
          title: "ID",
          sortField: "id",
        },
        {
          name: "created_at",
          title: "CREADO",
          sortField: "created_at",
          formatter: (value) => {
            return UtilService.date(value);
          },
        },
        {
          name: "province",
          title: "PROVINCIA",
          sortField: "province",
        },
        {
          name: "location",
          title: "LOCALIDAD",
          sortField: "location",
        },
        {
          name: "buttons-slot",
          title: "ACCIONES",
          titleClass: "text-right",
          dataClass: "text-right align-middle",
        },
      ],
      isAdminOrStaff: UserService.isAdminOrStaff(),
    };
  },
  methods: {
    /**
     *
     */
    async onXml() {
      this.isLoading = true;
      LocationService.export()
        .then((response) => {
          window.open(process.env.VUE_APP_API + '/operation_export/' + response.data.id, "_blank");
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },

    /**
     *
     */
    onSearch(event) {
      this.$refs.fulltable.search(event, {}, true);
    },

    /**
     *
     */
    onAdd() {
      this.$modal.show(
        LocationEdit,
        {
          onSave: {
            handler: () => {
              this.$refs.fulltable.refresh();
            },
          },
        },
        {
          styles: "margin-left: 10%",
          adaptive: true,
          width: "70%",
          height: "auto",
        }
      );
    },

    /**
     *
     */
    async onDelete(item) {
      const result = await AlertService.delete(
        "¿Estás seguro?",
        "Estas seguro de eliminar la localidad " + item.location
      );
      if (result.value) {
        this.isLoading = true;
        LocationService.destroy(item)
          .then(() => {
            this.isLoading = false;
            this.$refs.fulltable.refresh();
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Eliminar`,
                text: `Error al tratar de eliminar este elemento`,
                icon: "AlertOctagonIcon",
                variant: "danger",
              },
            });
          });
      }
    },

    /**
     *
     */
    async onView(item) {
      this.$modal.show(
        LocationView,
        {
          item: item,
        },
        {
          styles: "margin-left: 10%",
          adaptive: true,
          width: "70%",
          height: "auto",
        }
      );
    },

    /**
     *
     */
    async onEdit(item) {
      this.$modal.show(
        LocationEdit,
        {
          item: item,
          onClose: {
            handler: () => {},
          },
          onSave: {
            handler: () => {
              this.$refs.fulltable.refresh();
            },
          },
        },
        {
          styles: "margin-left: 10%",
          adaptive: true,
          width: "70%",
          height: "auto",
        }
      );
    },
  },
  mounted() {
    if (!UserService.isAdminOrStaff()) {
      this.$router.push("/");
    }

    LocationService.provinces().then(({ data }) => {
      this.provinces = data.data;
    });
  },
};
</script>